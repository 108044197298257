<script lang="ts">
  import classnames from 'classnames';
  import { slide } from 'svelte/transition';
  import { quadInOut } from 'svelte/easing';
  // Components
  import { Icon } from '$lib/components/atoms/Icon';

  export let variant: 'light' | 'dark' = 'light';
  export let isOpen: boolean = false;
  export let offset: number = 0;
  export let invertOrder: 'true' | 'false' = 'false';
  export let disableScroll: boolean = false;

  const classNames = classnames($$props.class, {
    accordion: true,
    'v-light': variant === 'light',
    'v-dark': variant === 'dark',
    'order-invert': invertOrder === 'true',
  });

  export let entry = {
    question: '',
    answer: ``,
  };

  let accordion: HTMLElement;

  const toggle = () => {
    const navbarHeight = getComputedStyle(document.body).getPropertyValue('--navbar-height');

    const timerLabel = document.querySelector('.timer-label');

    isOpen = !isOpen;
    if (isOpen) {
      const rect = accordion.getBoundingClientRect();
      if (!disableScroll) {
        window.scrollTo({
          top:
            rect.top -
            document.body.getBoundingClientRect().top -
            parseFloat(navbarHeight) -
            (offset > 0 ? offset : 0) -
            (timerLabel ? timerLabel.clientHeight : 0),
          behavior: 'smooth',
        }); 
      }
    }
  };
</script>

<dl bind:this={accordion} class={classNames}>
  <dt class="accordion-title" on:click={toggle} aria-expanded={isOpen}>
    <span class="accordion-title-text" class:opened={isOpen}>
      {#if $$slots['question']}
        <slot name="question" />
      {:else}
        {entry.question}
      {/if}</span
    >
    <span class="accordion-icon"><Icon icon="chevron-down" /></span>
  </dt>
  {#if isOpen}
    <dd
      class="accordion-content"
      in:slide={{ duration: 300, easing: quadInOut }}
      out:slide={{ duration: 300, easing: quadInOut }}
    >
      {#if $$slots['answer']}
        <slot name="answer" />
      {:else}
        {@html entry.answer}
      {/if}
    </dd>
  {/if}
</dl>

<style type="text/scss" lang="scss" scoped>
  .accordion {
    @apply w-full;

    &.v-light {
      @apply text-white;
    }

    &.v-dark {
      @apply text-gray-900;
    }
  }

  .accordion-title {
    @apply py-4;
    @apply flex items-center justify-between;
    @apply text-base md:text-md;
    @apply cursor-pointer;
  }

  .accordion-title-text {
    @apply transition-all duration-300;
    @apply flex-grow;
    @apply mr-4;
    @apply font-bold;
  }

  .order-invert .accordion-title-text {
    @apply order-1;
  }

  .accordion-icon {
    @apply inline-flex;
    @apply origin-center;
    @apply transition-all ease-in-out duration-300;
  }

  .accordion-content {
    @apply py-4;

    .v-light & {
      @apply text-white text-opacity-80;
    }
    .v-dark & {
      @apply text-gray-900;
    }
  }

  [aria-expanded='true'] .accordion-icon {
    @apply transform rotate-180;
  }
</style>
